import styles from "./styles/app.module.scss";
import BannerBG from "./components/BannerBG";
import Footer from "./components/Footer";
import Header from "./components/Header";
import CurrentNodeDetails from "./containers/CurrentNodeDetails";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import {
  fetchNodeHealthStatus,
  fetchRandomNodeData,
} from "./actions/nodes.actions";
import SearchBar from "./containers/SearchBar";
import RandomNodeLoader from "./containers/RandomNodeLoader";
import { dispatchInitialGraphData } from "./actions/historical.actions";

const App = () => {
  const dispatch = useDispatch();
  const { address } = useSelector((state) => state.nodes.currentNode);
  React.useEffect(() => {
    dispatch(fetchRandomNodeData());
  }, [dispatch]);

  React.useEffect(() => {
    if (address && address.length > 0) {
      dispatch(dispatchInitialGraphData(address));
      dispatch(fetchNodeHealthStatus(address));
    }
  }, [address, dispatch]);
  return (
    <>
      <BannerBG />
      <div className={styles.app}>
        <Header />
        <div className={styles.pageTop}>
          <span className={styles.pageTopTitle}>Node Dashboard</span>
          <SearchBar />
          <RandomNodeLoader />
        </div>
        <CurrentNodeDetails />
        <Footer />
      </div>
    </>
  );
};

export default App;
