import DropDown from "./DropDown";
import FilterButtons from "../FilterButtons";
import styles from "../Cards/styles.module.scss";
import React from "react";

const NodePrice = ({ title, prices }) => {
  const [state, setState] = React.useState({
    active: "hr",
  });
  const handleOnActiveChange = (key) => {
    setState((prevState) => ({ ...prevState, active: key }));
  };
  return (
    <section className={styles.nodeDetailsCardDetail}>
      <section className={styles.nodeDetailsCardDetailPrice}>
        <span className={styles.nodeDetailsCardDetailTitle}>{title}</span>
        <FilterButtons
          active={state.active}
          onActiveChange={handleOnActiveChange}
        />
      </section>
      <DropDown values={prices[state.active]} />
    </section>
  );
};

export default NodePrice;
