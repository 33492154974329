import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import ReactMapGl, { Layer, NavigationControl, Source } from "react-map-gl";
const layerStyle = {
  id: "point",
  paint: {
    "circle-color": "#00B6FA",
    "circle-radius": 7,
  },
  type: "circle",
};
const mapSettings = {
  doubleClickZoom: true,
  dragPan: false,
  dragRotate: false,
  interactive: false,
  maxPitch: 0,
  maxZoom: 8,
  minPitch: 0,
  minZoom: 2.5,
  renderWorldCopies: false,
  touchPitch: false,
  touchZoomRotate: false,
};
const Map = ({ latitude = 0.0, longitude = 0.0, zoom = [8] }) => {
  const mapRef = React.useRef();

  const onLoad = React.useCallback(() => {
    const map = mapRef.current.getMap();
    map.keyboard.disableRotation();
    map.touchZoomRotate.disableRotation();
  }, []);

  const handleFlyTo = (latitude, longitude, zoom) => {
    if (mapRef && mapRef?.current) {
      mapRef.current?.flyTo({
        center: [longitude, latitude],
        duration: 1000,
        essential: true,
        zoom: zoom,
      });
    }
  };

  const layer = React.useMemo(
    () => (
      <Source
        id="my-data"
        type="geojson"
        data={{
          features: [
            {
              geometry: {
                coordinates: [longitude, latitude],
                type: "Point",
              },
              properties: {
                latitude,
                longitude,
              },
              type: "Feature",
            },
          ],
          type: "FeatureCollection",
        }}
      >
        <Layer {...layerStyle} />
      </Source>
    ),
    [latitude, longitude]
  );

  React.useEffect(() => {
    handleFlyTo(latitude, longitude, zoom);
  }, [longitude, latitude, zoom]);

  return (
    <ReactMapGl
      {...mapSettings}
      ref={mapRef}
      initialViewState={{
        bearing: 0,
        pitch: 0,
        latitude: latitude,
        longitude: longitude,
        zoom: zoom,
      }}
      interactiveLayerIds={["point"]}
      cursor="default"
      mapboxAccessToken="pk.eyJ1IjoiYXNpZjc3NzQiLCJhIjoiY2t6MDBsemx3MGpmMjJ2bzE2aG54czI0byJ9.nae0UyMtDHUldZgbkLFnYQ"
      mapStyle="mapbox://styles/asif7774/cl0ekorls001214o2pk8vu229"
      style={{ width: "100%", height: "100%" }}
      onLoad={onLoad}
    >
      {layer}
      <NavigationControl position={"top-right"} />
    </ReactMapGl>
  );
};

export default Map;
