import React from "react";

const ArrowDown = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.29289 16.7071C7.68342 17.0976 8.31658 17.0976 8.70711 16.7071L15.0711 10.3431C15.4616 9.95262 15.4616 9.31946 15.0711 8.92893C14.6805 8.53841 14.0474 8.53841 13.6569 8.92893L8 14.5858L2.34315 8.92893C1.95262 8.53841 1.31946 8.53841 0.928933 8.92893C0.538408 9.31946 0.538408 9.95262 0.928933 10.3431L7.29289 16.7071ZM7 4.37114e-08L7 16L9 16L9 -4.37114e-08L7 4.37114e-08Z"
        fill="#0156FC"
      />
    </svg>
  );
};

export default React.memo(ArrowDown);
