import React from "react";
import styles from "./styles.module.scss";
const FilterButtons = ({ active, onActiveChange }) => {
  const [activeButton, setActiveButton] = React.useState(active);

  const handleOnButtonClick = (value) => {
    setActiveButton(value);
    onActiveChange(value);
  };
  return (
    <div className={styles.filterButtons}>
      <button
        className={`${activeButton === "hr" ? styles.active : ""}`}
        onClick={() => handleOnButtonClick("hr")}
        disabled={activeButton === "hr"}
      >
        Hr
      </button>
      <button
        className={`${activeButton === "gb" ? styles.active : ""}`}
        onClick={() => handleOnButtonClick("gb")}
        disabled={activeButton === "gb"}
      >
        GB
      </button>
    </div>
  );
};

export default FilterButtons;
