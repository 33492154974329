import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import { Provider as StoreProvider } from "react-redux";
import { store } from "./helpers/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StoreProvider store={store}>
    <App />
  </StoreProvider>
);
