import { createSlice } from "@reduxjs/toolkit";
import { dispatchInitialGraphData } from "../actions/historical.actions";

const initialState = {
  today: {
    title: "Today Stats",
    dataProvided: null,
    networkRevenue: {},
    uniqueUsers: null,
    loading: true,
  },
  week: {
    title: "This Week Stats",
    dataProvided: null,
    networkRevenue: {},
    uniqueUsers: null,
    loading: true,
  },
  month: {
    title: "This Month Stats",
    dataProvided: null,
    networkRevenue: {},
    uniqueUsers: null,
    loading: true,
  },
};
const aggregatedStatsSlice = createSlice({
  name: "aggregatedStats",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(dispatchInitialGraphData.pending, (state) => ({
        ...state,
        today: {
          ...initialState.today,
          loading: true,
        },
        month: {
          ...initialState.month,
          loading: true,
        },
        week: {
          ...initialState.week,
          loading: true,
        },
      }))
      .addCase(dispatchInitialGraphData.rejected, (state) => ({
        ...state,
        today: {
          ...state.today,
          dataProvided: null,
          networkRevenue: {},
          uniqueUsers: null,
          loading: false,
        },
        month: {
          ...state.month,
          dataProvided: null,
          networkRevenue: {},
          uniqueUsers: null,
          loading: false,
        },
        week: {
          ...state.week,
          dataProvided: null,
          networkRevenue: {},
          uniqueUsers: null,
          loading: false,
        },
      }))
      .addCase(dispatchInitialGraphData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          today: {
            ...state.today,
            dataProvided: payload.aggregatedStats.dataProvided.day,
            uniqueUsers: payload.aggregatedStats.uniqueUsers.day,
            networkRevenue: payload.aggregatedStats.networkRevenue.day,
            loading: false,
          },
          month: {
            ...state.month,
            dataProvided: payload.aggregatedStats.dataProvided.month,
            uniqueUsers: payload.aggregatedStats.uniqueUsers.month,
            networkRevenue: payload.aggregatedStats.networkRevenue.month,
            loading: false,
          },
          week: {
            ...state.week,
            dataProvided: payload.aggregatedStats.dataProvided.week,
            uniqueUsers: payload.aggregatedStats.uniqueUsers.week,
            networkRevenue: payload.aggregatedStats.networkRevenue.week,
            loading: false,
          },
        };
      });
  },
});

export default aggregatedStatsSlice.reducer;
