import { createSlice } from "@reduxjs/toolkit";
import {
  fetchNodeByAddress,
  fetchRandomNodeData,
} from "../actions/nodes.actions";
import { dispatchInitialGraphData } from "../actions/historical.actions";
const initialState = {
  loading: true,
  totalNodes: 0,
  currentNode: {
    isNodeHealthy: null,
    address: null,
    moniker: "-",
    location: { city: "-", country: "-", latitude: 0.0, longitude: 0.0 },
    speed: { upload: null, download: null },
    peers: 0,
    type: null,
    totalEarnings: "",
    version: null,
    remote_url: null,
    reach_status: null,
    prices: {
      hr: {},
      gb: {},
    },
  },
};
const nodesSlice = createSlice({
  name: "nodes",
  initialState,
  reducers: {
    SET_TOTAL_NODES_COUNT: (state, { payload }) => ({
      ...state,
      totalNodes: payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(dispatchInitialGraphData.pending, (state) => ({ ...state }))
      .addCase(dispatchInitialGraphData.rejected, (state) => ({
        ...state,
        currentNode: {
          ...state.currentNode,
          ...initialState.currentNode,
        },
      }))
      .addCase(dispatchInitialGraphData.fulfilled, (state, { payload }) => {
        return {
          ...state,
          currentNode: {
            ...state.currentNode,
            totalEarnings: payload.aggregatedStats.networkRevenue.total,
          },
        };
      });

    builder
      .addCase(fetchRandomNodeData.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(fetchRandomNodeData.rejected, (state) => ({
        ...state,
        loading: false,
        currentNode: {
          ...state.currentNode,
          ...initialState.currentNode,
        },
      }))
      .addCase(fetchRandomNodeData.fulfilled, (state, { payload }) => ({
        ...state,
        loading: false,
        currentNode: {
          ...state.currentNode,
          ...payload,
        },
      }));

    builder
      .addCase(fetchNodeByAddress.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(fetchNodeByAddress.rejected, (state, { payload }) => ({
        ...state,
        loading: false,
        currentNode: {
          ...state.currentNode,
          ...initialState.currentNode,
          address: payload.addr,
        },
      }))
      .addCase(fetchNodeByAddress.fulfilled, (state, { payload }) => ({
        ...state,
        loading: false,
        currentNode: {
          ...state.currentNode,
          ...payload,
        },
      }));
  },
});
export const { SET_TOTAL_NODES_COUNT } = nodesSlice.actions;
export default nodesSlice.reducer;
