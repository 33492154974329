import React from "react";

const ShuffleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M15.5743 8.40276C13.0853 8.93612 11.7815 11.4251 10.4778 13.3807C9.35184 14.8622 7.81107 16.2845 5.85547 16.2845V17.4697C7.98886 17.5882 10.4778 15.3363 11.426 13.914C12.1371 12.9066 13.8557 9.94358 15.5743 9.64727V11.2473L19.1892 8.87686L15.5743 6.80273V8.40276Z"
        fill="white"
      />
      <path
        d="M10.4107 11.6018L11.0626 10.6536C9.93665 9.23133 7.744 7.45351 5.84766 7.57203V8.7572C7.80326 8.75723 9.28477 10.1203 10.4107 11.6018Z"
        fill="white"
      />
      <path
        d="M15.5718 15.454C14.6829 15.2762 13.7347 14.3281 13.0236 13.3799L12.3125 14.3873C13.1104 15.4876 14.2604 16.2822 15.5718 16.6392V18.2393L19.1868 16.1651L15.5718 13.7947V15.454Z"
        fill="white"
      />
      <path
        d="M12.521 1.21057e-08C10.0447 -0.000108857 7.62399 0.734096 5.56495 2.10977C3.50591 3.48544 1.90104 5.4408 0.95329 7.72857C0.0055411 10.0163 -0.242522 12.5338 0.240469 14.9625C0.72346 17.3913 1.91581 19.6223 3.66675 21.3734C5.41768 23.1245 7.64856 24.317 10.0773 24.8003C12.506 25.2835 15.0234 25.0357 17.3113 24.0881C19.5992 23.1406 21.5547 21.5359 22.9305 19.477C24.3064 17.4181 25.0408 14.9974 25.041 12.5211C25.0372 9.20166 23.717 6.01921 21.3699 3.67188C19.0228 1.32456 15.8405 0.00403735 12.521 1.21057e-08ZM12.521 24.2034C10.2105 24.2034 7.95182 23.5182 6.03066 22.2345C4.10951 20.9509 2.61215 19.1263 1.72795 16.9916C0.843743 14.857 0.612401 12.508 1.06318 10.2419C1.51395 7.97572 2.6266 5.89412 4.26042 4.26032C5.89423 2.62652 7.97584 1.51389 10.242 1.06314C12.5082 0.612382 14.8571 0.843746 16.9918 1.72797C19.1264 2.61219 20.951 4.10957 22.2346 6.03073C23.5183 7.9519 24.2034 10.2106 24.2034 12.5211C24.1999 15.6184 22.9679 18.5878 20.7778 20.7778C18.5877 22.9679 15.6183 24.1999 12.521 24.2034Z"
        fill="white"
      />
    </svg>
  );
};

export default React.memo(ShuffleIcon);
