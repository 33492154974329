import axios from "axios";
import { abortAPICall, fetchData } from "./fetchData";
const durations = [
  { duration: "day", limit: 1 },
  { duration: "week", limit: 1 },
  { duration: "month", limit: 1 },
  { duration: "year", limit: 0 },
];

const cancelTokens = {
  fetchRandomNodeDataService: null,
  fetchNodeByAddressService: null,
  fetchStatsOfNodeService: null,
  fetchNodeHealthStatusService: null,
};

export const fetchTotalNodesCountService = async () => {
  const response = await fetchData("/statistics", null, {
    method: "CurrentNodeCount",
    status: "active",
  });
  return response.result?.flat(1)[0]?.value;
};

export const fetchRandomNodeDataService = async (randomNumber) => {
  try {
    if (cancelTokens.fetchRandomNodeDataService) {
      abortAPICall(cancelTokens.fetchRandomNodeDataService);
    }
    const cancelToken = axios.CancelToken.source();
    cancelTokens.fetchRandomNodeDataService = cancelToken;

    const response = await fetchData(
      "/nodes",
      null,
      {
        limit: 1,
        skip: randomNumber,
        status: "active",
        sort: "-register_height",
      },
      cancelToken.token
    );
    return response.result?.flat(1)[0];
  } catch (e) {
    return [].flat(1);
  }
};

export const fetchNodeByAddressService = async (addr) => {
  try {
    if (cancelTokens.fetchNodeByAddressService) {
      abortAPICall(cancelTokens.fetchNodeByAddressService);
    }
    const cancelToken = axios.CancelToken.source();
    cancelTokens.fetchNodeByAddressService = cancelToken;

    const response = await fetchData(
      `/nodes/${addr}`,
      null,
      {
        limit: 1,
        sort: "-register_height",
      },
      cancelToken.token
    );
    return response.result;
  } catch (e) {
    return [].flat(1);
  }
};

export const fetchStatsOfNodeServiceAggregated = async (addr) => {
  try {
    const promises = [];

    for (let index = 0; index < durations.length; index++) {
      promises.push(
        fetchData(`/nodes/${addr}/statistics`, null, {
          limit: durations[index].limit,
          skip: 0,
          sort: "-timestamp",
          timeframe: durations[index].duration,
        })
      );
    }
    const responses = [];

    for (const promise of promises) {
      try {
        const resp = await promise;
        responses.push(resp.result);
      } catch (error) {
        responses.push({ success: false, result: [] });
      }
    }
    const [day, week, month, year] = responses;
    return {
      day,
      week,
      month,
      year,
    };
  } catch (e) {
    return {
      day: [],
      week: [],
      month: [],
      year: [],
    };
  }
};

export const fetchStatsOfNodeServiceHistorical = async (
  addr,
  duration = "day"
) => {
  try {
    if (cancelTokens.fetchStatsOfNodeService) {
      abortAPICall(cancelTokens.fetchStatsOfNodeService);
    }
    const cancelToken = axios.CancelToken.source();
    cancelTokens.fetchStatsOfNodeService = cancelToken;

    const response = await fetchData(
      `/nodes/${addr}/statistics`,
      null,
      {
        limit: 0,
        skip: 0,
        sort: "-timestamp",
        timeframe: duration,
      },
      cancelToken.token
    );
    return response.result?.flat(1);
  } catch (e) {
    return [].flat(1);
  }
};

export const fetchNodeHealthStatusService = async (addr) => {
  try {
    if (cancelTokens.fetchNodeHealthStatusService) {
      abortAPICall(cancelTokens.fetchNodeHealthStatusService);
    }
    const cancelToken = axios.CancelToken.source();
    cancelTokens.fetchNodeHealthStatusService = cancelToken;

    const response = await axios.get(
      `https://api.health.sentinel.co/v1/records/${addr}`,
      { cancelToken: cancelToken.token }
    );
    return response.data.result;
  } catch (e) {
    return {};
  }
};
