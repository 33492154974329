import React from "react";
import Card from "./Card";
import styles from "./styles.module.scss";
import DurationButtons from "../DurationButtons";
import Loader from "../Loader";
import Graph from "../Graph";
import { sortByKeys } from "../../helpers/sortByKeys";

const GraphCard = ({
  title,
  isLoading,
  duration,
  values = {},
  labels = [],
  onDurationChange,
  isBandwidth,
  parentLoading,
}) => {
  const noOfKeys = Object.keys(values)?.length ?? 0;
  const data = sortByKeys({ ...values });
  const colors =
    noOfKeys > 2
      ? ["#0080FE", "#264286", "#FFFFFF", "#1F51FF", "#0096FF", "#013DB3"]
      : ["#264286", "#FFFFFF", "#1F51FF"];
  return (
    <Card>
      <div className={styles.graphCard}>
        <section className={styles.graphCardTop}>
          <span className={styles.graphCardTitle}>{title}</span>
          <DurationButtons
            duration={duration}
            onDurationChange={onDurationChange}
          />
        </section>
        <section className={styles.graphCardBottom}>
          {parentLoading || isLoading ? (
            <section className={styles.graphCardLoader}>
              <Loader />
            </section>
          ) : noOfKeys === 0 ? (
            <section className={styles.graphCardLoader}>
              <h1>No Data</h1>
            </section>
          ) : (
            <Graph
              isBandwidth={isBandwidth}
              showLegend={noOfKeys > 1}
              labels={labels}
              data={data}
              colors={colors}
            />
          )}
        </section>
      </div>
    </Card>
  );
};

export default GraphCard;
