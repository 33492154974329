import React from "react";
import SentinelLogo from "../../assets/logos/SentinelLogo";
import styles from "./styles.module.scss";
import { SocialLinks } from "../Links";

const Header = () => {
  return (
    <div className={styles.header}>
      <SentinelLogo />
      <section className={styles.hideOnMObile}>
        <SocialLinks />
      </section>
    </div>
  );
};

export default Header;
