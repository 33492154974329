import React from "react";
import styles from "./styles.module.scss";

const DropDown = ({ values = {} }) => {
  const value = values.DVPN;
  return (
    <section className={styles.dropdown}>
      <span className={styles.value}>
        {value ? `${Number(value).toLocaleString()} DVPN` : "NA"}
      </span>
    </section>
  );
};

export default DropDown;
