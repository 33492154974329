import React from "react";
import styles from "../styles/current-node-details.module.scss";
import { useSelector } from "react-redux";
import { NodeStatsCard } from "../components/Cards";

const NodeStatsCards = ({ parentLoading }) => {
  const stats = useSelector((state) => state.aggregatedStats);
  return (
    <div className={styles.currentNodeDetailsStats}>
      {Object.values(stats).map((stat) => {
        return (
          <NodeStatsCard
            parentLoading={parentLoading}
            {...stat}
            key={stat.title}
          />
        );
      })}
    </div>
  );
};

export default React.memo(NodeStatsCards);
