import React from "react";
import Card from "./Card";
import styles from "./styles.module.scss";
import { formatBytes } from "../../helpers/formatBytes";
import DropDown from "../DropDown/DropDown";
import Loader from "../Loader";

const NodeStatsCard = ({
  title,
  dataProvided,
  networkRevenue,
  uniqueUsers,
  loading,
  parentLoading,
}) => {
  return (
    <Card>
      <div className={styles.nodeStatsCard}>
        <section className={styles.nodeStatsCardTop}>
          <span className={styles.nodeStatsCardTopTitle}>{title}</span>
        </section>
        {parentLoading || loading ? (
          <section className={styles.loader}>
            <Loader />
          </section>
        ) : (
          <div className={styles.nodeStatsCardBottom}>
            <section className={styles.nodeStatsCardBottomDetails}>
              <span className={styles.nodeStatsCardBottomDetailsTitle}>
                Bandwidth Provided
              </span>
              <span className={styles.nodeStatsCardBottomDetailsValue}>
                {formatBytes(dataProvided) || "NA"}
              </span>
            </section>
            <section className={styles.nodeStatsCardBottomDetails}>
              <span className={styles.nodeStatsCardBottomDetailsTitle}>
                Unique Users
              </span>
              <span className={styles.nodeStatsCardBottomDetailsValue}>
                {uniqueUsers ? String(uniqueUsers).toLocaleUpperCase() : 0}
              </span>
            </section>
            <section className={styles.nodeStatsCardBottomDetails}>
              <span className={styles.nodeStatsCardBottomDetailsTitle}>
                Earnings
              </span>
              <span className={styles.nodeStatsCardBottomDetailsValue}>
                <DropDown values={networkRevenue} />
              </span>
            </section>
          </div>
        )}
      </div>
    </Card>
  );
};

export default NodeStatsCard;
