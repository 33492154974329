import React from "react";
import styles from "./styles.module.scss";
const Card = ({ children }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardContainer}>{children}</div>
    </div>
  );
};

export default React.memo(Card);
