import React from "react";
import SearchIcon from "../assets/icons/SearchIcon";

import styles from "../styles/search-bar.module.scss";
import { useDispatch } from "react-redux";
import { fetchNodeByAddress } from "../actions/nodes.actions";
const SearchBar = () => {
  const [searchKey, setSearchKey] = React.useState("");
  const dispatch = useDispatch();

  const handleOnSearchKeyChange = (event) => {
    event.preventDefault();
    setSearchKey(event.target.value);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    if (
      searchKey &&
      searchKey !== "" &&
      searchKey.toLowerCase().startsWith("sent")
    ) {
      setSearchKey("");
      dispatch(fetchNodeByAddress(searchKey));
    }
  };

  return (
    <div className={styles.searchbar}>
      <section className={styles.searchbarInput}>
        <form onSubmit={() => handleSearch()}>
          <input
            type="text"
            value={searchKey}
            onChange={handleOnSearchKeyChange}
            placeholder="Type in the Node Address to View Info"
          />
          <button onClick={handleSearch}>
            <SearchIcon />
          </button>
        </form>
      </section>
    </div>
  );
};

export default React.memo(SearchBar);
