import { combineReducers, createSlice } from "@reduxjs/toolkit";
import {
  dispatchActiveSessions,
  dispatchActiveSubscriptions,
  dispatchBandwidthConsumtion,
  dispatchDVPNEarning,
  dispatchInitialGraphData,
} from "../actions/historical.actions";
import { graphModel } from "../constants/graph.model.constants";

const activeSubscriptionSlice = createSlice({
  name: "activeSubscriptions",
  initialState: {
    ...graphModel,
    title: "Active Subscriptions",
    dispatcher: "dispatchActiveSubscriptions",
  },
  extraReducers: (builder) => {
    builder
      .addCase(dispatchInitialGraphData.pending, (state) => ({
        ...state,
        ...graphModel,
        loading: true,
      }))
      .addCase(dispatchInitialGraphData.rejected, (state) => ({
        ...state,
        ...graphModel,
        loading: false,
      }))
      .addCase(dispatchInitialGraphData.fulfilled, (state, { payload }) => ({
        ...state,
        duration: payload.duration,
        loading: false,
        ...(payload.activeSubscriptions
          ? {
              [payload.duration]: payload.activeSubscriptions,
            }
          : {}),
      }));

    builder
      .addCase(dispatchActiveSubscriptions.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(dispatchActiveSubscriptions.rejected, (state) => ({
        ...state,
        loading: false,
      }))
      .addCase(dispatchActiveSubscriptions.fulfilled, (state, { payload }) => ({
        ...state,
        duration: payload.duration,
        loading: false,
        ...(payload.data
          ? {
              [payload.duration]: payload.data,
            }
          : {}),
      }));
  },
});

const activeSessionsSlice = createSlice({
  name: "activeSessions",
  initialState: {
    ...graphModel,
    title: "Active Sessions",
    dispatcher: "dispatchActiveSessions",
  },
  extraReducers: (builder) => {
    builder
      .addCase(dispatchInitialGraphData.pending, (state) => ({
        ...state,
        ...graphModel,
        loading: true,
      }))
      .addCase(dispatchInitialGraphData.rejected, (state) => ({
        ...state,
        ...graphModel,
        loading: false,
      }))
      .addCase(dispatchInitialGraphData.fulfilled, (state, { payload }) => ({
        ...state,
        duration: payload.duration,
        loading: false,
        ...(payload.activeSessions
          ? {
              [payload.duration]: payload.activeSessions,
            }
          : {}),
      }));

    builder
      .addCase(dispatchActiveSessions.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(dispatchActiveSessions.rejected, (state) => ({
        ...state,
        loading: false,
      }))
      .addCase(dispatchActiveSessions.fulfilled, (state, { payload }) => ({
        ...state,

        duration: payload.duration,
        loading: false,
        ...(payload.data
          ? {
              [payload.duration]: payload.data,
            }
          : {}),
      }));
  },
});

const sessionBandwidthSlice = createSlice({
  name: "sessionBandwidth",
  initialState: {
    ...graphModel,
    title: "Bandwidth Provided",
    dispatcher: "dispatchBandwidthConsumtion",
    isBandwidth: true,
  },
  extraReducers: (builder) => {
    builder
      .addCase(dispatchInitialGraphData.pending, (state) => ({
        ...state,
        ...graphModel,
        loading: true,
      }))
      .addCase(dispatchInitialGraphData.rejected, (state) => ({
        ...state,
        ...graphModel,
        loading: false,
      }))
      .addCase(dispatchInitialGraphData.fulfilled, (state, { payload }) => ({
        ...state,
        duration: payload.duration,
        loading: false,
        ...(payload.sessionBandwidth
          ? {
              [payload.duration]: payload.sessionBandwidth,
            }
          : {}),
      }));

    builder
      .addCase(dispatchBandwidthConsumtion.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(dispatchBandwidthConsumtion.rejected, (state) => ({
        ...state,
        loading: false,
      }))
      .addCase(dispatchBandwidthConsumtion.fulfilled, (state, { payload }) => ({
        ...state,
        duration: payload.duration,
        loading: false,
        ...(payload.data
          ? {
              [payload.duration]: payload.data,
            }
          : {}),
      }));
  },
});

const dvpnEarningSlice = createSlice({
  name: "dvpnEarning",
  initialState: {
    ...graphModel,
    title: "DVPN Earned",
    dispatcher: "dispatchDVPNEarning",
  },
  extraReducers: (builder) => {
    builder
      .addCase(dispatchInitialGraphData.pending, (state) => ({
        ...state,
        ...graphModel,
        loading: true,
      }))
      .addCase(dispatchInitialGraphData.rejected, (state) => ({
        ...state,
        ...graphModel,
        loading: false,
      }))
      .addCase(dispatchInitialGraphData.fulfilled, (state, { payload }) => ({
        ...state,
        duration: payload.duration,
        loading: false,
        ...(payload.dvpnEarning
          ? {
              [payload.duration]: payload.dvpnEarning,
            }
          : {}),
      }));

    builder
      .addCase(dispatchDVPNEarning.pending, (state) => ({
        ...state,

        loading: true,
      }))
      .addCase(dispatchDVPNEarning.rejected, (state) => ({
        ...state,
        loading: false,
      }))
      .addCase(dispatchDVPNEarning.fulfilled, (state, { payload }) => ({
        ...state,

        duration: payload.duration,
        loading: false,
        ...(payload.data
          ? {
              [payload.duration]: payload.data,
            }
          : {}),
      }));
  },
});

const historicalReducer = combineReducers({
  sessionBandwidth: sessionBandwidthSlice.reducer,
  dvpnEarning: dvpnEarningSlice.reducer,
  activeSubscriptions: activeSubscriptionSlice.reducer,
  activeSessions: activeSessionsSlice.reducer,
});

export default historicalReducer;
