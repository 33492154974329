import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchStatsOfNodeServiceAggregated,
  fetchStatsOfNodeServiceHistorical,
} from "../services/nodes.service";
import { sortByDates } from "../helpers/sortByKeys";
import {
  formatActiveSessions,
  formatActiveSubscriptions,
  formatAggregatedStats,
  formatBandwidthConsumption,
  formatDVPNEarnings,
} from "../helpers/formatNodes";

export const dispatchBandwidthConsumtion = createAsyncThunk(
  "DISPATCH_BANDWIDTH_CONSUMTION",
  async (
    { duration, address },
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    try {
      const { sessionBandwidth } = getState().historical;
      const node = getState().nodes.currentNode;

      if (
        sessionBandwidth[duration] &&
        sessionBandwidth[duration].labels &&
        sessionBandwidth[duration].labels.length > 0
      ) {
        return fulfillWithValue({ duration });
      }

      const addr = address ? address : node.address;
      const response = await fetchStatsOfNodeServiceHistorical(addr, duration);
      const stats = sortByDates(response);
      const data = formatBandwidthConsumption(stats, duration);
      return fulfillWithValue({ data, duration });
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const dispatchActiveSubscriptions = createAsyncThunk(
  "DISPATCH_ACTIVE_SUBSCRIPTIONS",
  async (
    { duration, address },
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    try {
      const { activeSubscriptions } = getState().historical;
      const node = getState().nodes.currentNode;

      if (
        activeSubscriptions[duration] &&
        activeSubscriptions[duration].labels &&
        activeSubscriptions[duration].labels.length > 0
      ) {
        return fulfillWithValue({ duration });
      }

      const addr = address ? address : node.address;
      const response = await fetchStatsOfNodeServiceHistorical(addr, duration);
      const stats = sortByDates(response);
      const data = formatActiveSubscriptions(stats, duration);
      return fulfillWithValue({ data, duration });
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const dispatchActiveSessions = createAsyncThunk(
  "DISPATCH_ACTIVE_SESSIONS",
  async (
    { duration, address },
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    try {
      const { activeSessions } = getState().historical;
      const node = getState().nodes.currentNode;

      if (
        activeSessions[duration] &&
        activeSessions[duration].labels &&
        activeSessions[duration].labels.length > 0
      ) {
        return fulfillWithValue({ duration });
      }

      const addr = address ? address : node.address;
      const response = await fetchStatsOfNodeServiceHistorical(addr, duration);
      const stats = sortByDates(response);
      const data = formatActiveSessions(stats, duration);
      return fulfillWithValue({ data, duration });
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const dispatchDVPNEarning = createAsyncThunk(
  "DISPATCH_DVPN_EARNING",
  async (
    { duration, address },
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    try {
      const { dvpnEarning } = getState().historical;
      const node = getState().nodes.currentNode;

      if (
        dvpnEarning[duration] &&
        dvpnEarning[duration].labels &&
        dvpnEarning[duration].labels.length > 0
      ) {
        return fulfillWithValue({ duration });
      }

      const addr = address ? address : node.address;
      const response = await fetchStatsOfNodeServiceHistorical(addr, duration);
      const stats = sortByDates(response);
      const data = formatDVPNEarnings(stats, duration);
      return fulfillWithValue({ data, duration });
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const dispatchInitialGraphData = createAsyncThunk(
  "DISPATCH_INITIAL_GRAPH_DATA",
  async (addr, { rejectWithValue, fulfillWithValue }) => {
    try {
      const duration = "day";
      const response = await fetchStatsOfNodeServiceHistorical(addr, duration);
      const aggregated = await fetchStatsOfNodeServiceAggregated(addr);
      const stats = sortByDates(response);
      const aggregatedStats = formatAggregatedStats(aggregated);

      const sessionBandwidth = formatBandwidthConsumption(stats, duration);
      const activeSubscriptions = formatActiveSubscriptions(stats, duration);
      const activeSessions = formatActiveSessions(stats, duration);
      const dvpnEarning = formatDVPNEarnings(stats, duration);

      return fulfillWithValue({
        sessionBandwidth,
        activeSessions,
        activeSubscriptions,
        dvpnEarning,
        aggregatedStats,
        duration,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
