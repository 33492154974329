export const sortByKeys = (obj) => {
  const ordered = Object.keys(obj)
    .sort()
    .reduce((prev, curr) => {
      return { ...prev, [curr]: obj[curr] };
    }, {});

  return ordered;
};

export const sortByDates = (array = []) => {
  return array.sort(function (a, b) {
    const dateA = new Date(a.timestamp);
    const dateB = new Date(b.timestamp);

    if (isNaN(dateA) || isNaN(dateB)) {
      return 0;
    }

    return dateA - dateB;
  });
};
