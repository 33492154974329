import React from "react";
import styles from "./styles.module.scss";

const DurationButtons = ({
  isLoading = false,
  duration,
  onDurationChange = () => ({}),
}) => {
  const handlenDurationChange = (value) => {
    onDurationChange(value);
  };
  const buttons = [
    {
      value: "day",
    },
    {
      value: "week",
    },
    {
      value: "month",
    },
    {
      value: "year",
    },
  ];
  return (
    <section className={styles.durationButtons}>
      {buttons.map((button) => {
        const disabled = button.value === duration;
        return (
          <button
            key={button.value}
            className={`${styles.durationButton} ${
              disabled && styles.durationButtonActive
            }`}
            onClick={() => handlenDurationChange(button.value)}
            disabled={disabled || isLoading}
            duration={button.value}
          >
            {button.value.toUpperCase()}
          </button>
        );
      })}
    </section>
  );
};

export default DurationButtons;
