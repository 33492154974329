import React from "react";
import { groups } from "../../constants/links.constants";
import styles from "./styles.module.scss";

const CommunityLinks = () => {
  return (
    <div className={styles.community}>
      {groups.map(({ title, links }) => {
        return (
          <section key={title} className={styles.group}>
            <span className={styles.groupTitle}>{title}</span>
            <section className={styles.groupLinks}>
              {links.map((link) => {
                return (
                  <a
                    className={styles.groupLinksLink}
                    key={link.title}
                    href={link.href}
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    {link.title}
                  </a>
                );
              })}
            </section>
          </section>
        );
      })}
    </div>
  );
};

export default CommunityLinks;
