import React from "react";

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M18.2384 17.1723L13.3991 12.333C14.6212 10.8307 15.2202 8.91672 15.0729 6.98558C14.9254 5.05456 14.0426 3.25382 12.6065 1.95443C11.1705 0.655157 9.2906 -0.0436047 7.35454 0.00210948C5.41842 0.0480181 3.57365 0.834859 2.20067 2.20067C0.834905 3.57369 0.0480421 5.41851 0.00211185 7.35453C-0.0436283 9.29065 0.655116 11.1705 1.95443 12.6065C3.25387 14.0426 5.05461 14.9254 6.98558 15.0729C8.9166 15.2202 10.8306 14.6212 12.333 13.3991L17.1723 18.2384C17.3671 18.4052 17.6329 18.4619 17.8787 18.3893C18.1244 18.3165 18.3167 18.1243 18.3894 17.8786C18.462 17.6328 18.4053 17.367 18.2385 17.1722L18.2384 17.1723ZM3.26679 11.8266C2.13188 10.6924 1.49409 9.15363 1.49374 7.54919C1.4934 5.94457 2.13054 4.40562 3.26493 3.27089C4.39949 2.13616 5.93826 1.49867 7.54288 1.49867C9.14733 1.49867 10.6863 2.13616 11.8207 3.27089C12.9552 4.40562 13.5924 5.94457 13.592 7.54919C13.5917 9.15363 12.9537 10.6924 11.8188 11.8266C10.6895 12.9694 9.14953 13.6127 7.54288 13.6127C5.93606 13.6127 4.39612 12.9694 3.26679 11.8266Z"
        fill="#606572"
      />
    </svg>
  );
};

export default React.memo(SearchIcon);
