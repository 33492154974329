import { Axios } from "./api";

export const abortAPICall = (cancelToken) => {
  if (cancelToken) cancelToken.cancel();
};

export const fetchData = async (
  url = "",
  duration = null,
  params = {},
  cancelToken
) => {
  try {
    const sort = params?.sort || "-timestamp";
    const response = await Axios.get(url, {
      cancelToken,
      params: {
        sort,
        ...(duration ? { timeframe: String(duration).toLowerCase() } : {}),
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
