import React from "react";
import BGWorld from "../../assets/images/bg-world-desktop.png";
import BGWorldMobile from "../../assets/images/bg-world-mobile.png";

import styles from "./styles.module.scss";

const BannerBG = () => {
  return (
    <div className={styles.banner}>
      <picture className={styles.top}>
        <source
          className={styles.top}
          media="(max-width: 800px)"
          width={"100%"}
          height={"auto"}
          srcSet={BGWorldMobile}
        />
        <img
          className={styles.top}
          src={BGWorld}
          alt=""
          width={"100%"}
          height={"auto"}
        />
      </picture>
    </div>
  );
};

export default BannerBG;
