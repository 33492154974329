import zoom from "chartjs-plugin-zoom";
import styles from "./styles.module.scss";
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import { formatBytes } from "../../helpers/formatBytes";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoom
);

const zoomOptions = {
  pan: {
    mode: "x",
    enabled: true,
    modifierKey: "shift",
  },
  zoom: {
    drag: {
      enabled: true,
    },
    pinch: {
      enabled: true,
    },
    mode: "x",
  },
};

const getDataSets = (obj, colors) => {
  const dataSets = [];
  const backgroundColors = [...colors, "#013DB3", "#264286", "#F0EFEF"];
  for (let i = 0; i < Object.keys(obj).length; i++) {
    dataSets.push({
      data: Object.values(obj)[i],
      label: Object.keys(obj)[i],
      backgroundColor: backgroundColors[i] ?? "#FFFFFF",
    });
  }
  return dataSets;
};

const options = (showLegend, isBandwidth) => ({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {
        callback: function (val) {
          if (isBandwidth) return formatBytes(val);
          return val;
        },
      },
    },
  },
  plugins: {
    datalabels: {
      display: true,
    },
    tooltip: {
      callbacks: isBandwidth
        ? {
            title: (xDatapoint) => {
              return xDatapoint.raw;
            },
            label: (yDatapoint) => {
              return `${yDatapoint.dataset.label}: ${formatBytes(
                yDatapoint.raw
              )}`;
            },
          }
        : {
            title: (xDatapoint) => {
              return xDatapoint.raw;
            },
            label: (yDatapoint) => {
              return `${yDatapoint.dataset.label}: ${yDatapoint.raw}`;
            },
          },
    },
    zoom: zoomOptions,
    legend: showLegend
      ? {
          position: "bottom",
          align: "start",
          labels: {
            color: "#FFFFFF",
          },
        }
      : false,
  },
});

const Graph = ({ data, labels, showLegend, colors, isBandwidth }) => {
  const chartRef = React.useRef(null);

  const graphdata = {
    labels,
    datasets: getDataSets(data, colors),
  };

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  return (
    <div className={styles.root}>
      <button onClick={handleResetZoom}>Reset Zoom</button>
      <Bar
        ref={chartRef}
        height={"100%"}
        width={"100%"}
        options={options(showLegend, isBandwidth)}
        data={graphdata}
      />
    </div>
  );
};

Graph.defaultProps = {
  data: {},
  labels: [],
  showLegend: true,
  colors: [],
  isFullWidth: false,
};

Graph.propTypes = {
  isFullWidth: PropTypes.bool,
  data: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
  showLegend: PropTypes.bool,
  colors: PropTypes.array,
};

export default Graph;
