const durations = {
  DAY: "Day",
  WEEK: "Week",
  MONTH: "Month",
  YEAR: "Year",
};

const matchStakers = (value) => {
  const stakers = {
    "ibc/31FEE1A2A9F9C01113F90BD0BBCCE8FD6BBB8585FAF109A2101827DD1D5B95B8":
      "SCRT",
    "ibc/A8C2D23A1E6F95DA4E48BA349667E322BD7A6C996D8A4AAE8BA72E190F3D1477":
      "ATOM",
    "ibc/B1C0DDB14F25279A2026BC8794E12B259F8BDA546A3C5132CCAEE4431CE36783":
      "DEC",
    "ibc/ED07A3391A112B175915CD8FAF43A2DA8E4790EDE12566649D0C2F97716B8518":
      "OSMO",
    udvpn: "DVPN",
  };
  return stakers[value] ?? value;
};

export const sortObjByKey = (obj) => {
  const keyValueArray = Object.entries(obj);
  if (keyValueArray && keyValueArray.length > 0) {
    keyValueArray.sort((a, b) => a[0].localeCompare(b[0]));
    const sortedObject = Object.fromEntries(keyValueArray);
    return sortedObject;
  }
  return obj;
};

export const parseNodePrices = (prices = []) => {
  if (prices && prices.length > 0) {
    return prices.reduce((prev, curr) => {
      const denom = matchStakers(curr.denom) || curr.denom;
      return {
        ...prev,
        [denom]: (Number.parseInt(curr.amount) / 1e6).toFixed(2),
      };
    }, {});
  }
  return {};
};

export const parseDatesForDuration = (timestamps, duration) => {
  const dates = [];
  timestamps.forEach((timestamp) => {
    const dt = new Date(timestamp);
    switch (duration) {
      case durations.MONTH:
        dates.push(`${dt.getMonth() + 1}/${dt.getFullYear()}`);
        break;
      case durations.YEAR:
        dates.push(`${dt.getFullYear()}`);
        break;
      case durations.WEEK:
      case durations.DAY:
      default:
        dates.push(`${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`);
        break;
    }
  });
  return dates;
};

export const parseTimeStamp = (timestamp, duration) => {
  const dt = new Date(timestamp);
  switch (duration) {
    case durations.MONTH:
      return `${dt.getMonth() + 1}/${dt.getFullYear()}`;
    case durations.YEAR:
      return `${dt.getFullYear()}`;
    case durations.WEEK:
    case durations.DAY:
    default:
      return `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`;
  }
};

export const parseNumberNull = (value) => {
  if (value) return value;
  return 0;
};

export const parseValueNotAvailable = (value) => {
  if (value) {
    return value;
  }
  return "NA";
};

export const parseNodeLocation = (location) => {
  if (location) {
    const city = location.city || "NA";
    const country = location.country || "NA";
    const latitude = location.latitude || 0.0;
    const longitude = location.longitude || 0.0;

    return { city, country, latitude, longitude };
  }
  return { city: "NA", country: "NA", latitude: 0.0, longitude: 0.0 };
};

export const parseTotalDataProvided = (session_bandwidth) => {
  if (session_bandwidth) {
    return (
      Number.parseInt(session_bandwidth.upload) +
      Number.parseInt(session_bandwidth.download)
    );
  }
  return 0;
};

export const parseBandwidthValue = (data = [], duration) => {
  const labels = [],
    Upload = [],
    Download = [];
  data.forEach((record) => {
    if (record.session_bandwidth) {
      Upload.push(Number.parseInt(record.session_bandwidth.upload));
      Download.push(Number.parseInt(record.session_bandwidth.download));
      labels.push(parseTimeStamp(record.timestamp, duration));
    }
  });
  return { labels, Upload, Download };
};

export const parseDVPNEarned = (stats = []) => {
  try {
    const values = [];
    stats.forEach((stat) => {
      let value = 0;
      if (stat.bytes_earning) {
        value += Number.parseInt(stat.bytes_earning[0]?.amount);
      }
      if (stat.hours_earning) {
        value += Number.parseInt(stat.hours_earning[0]?.amount);
      }

      values.push((value / 1e6).toFixed(2));
    });
    return values;
  } catch (e) {
    return [];
  }
};

export const parseAggregatedEarning = (frame = {}) => {
  let total = {};
  const denoms = [];
  if (frame) {
    frame.hours_earning?.forEach((earn) => {
      const denom = matchStakers(earn.denom);
      if (!denoms.includes(denom)) {
        denoms.push(denom);
        total[denom] = (Number.parseInt(earn.amount) / 1e6).toFixed(2);
      } else {
        total[denom] += (Number.parseInt(earn.amount) / 1e6).toFixed(2);
      }
    });
    frame.bytes_earning?.forEach((earn) => {
      const denom = matchStakers(earn.denom);
      if (!denoms.includes(denom)) {
        denoms.push(denom);
        total[denom] = (Number.parseInt(earn.amount) / 1e6).toFixed(2);
      } else {
        total[denom] = (
          Number.parseFloat(total[denom]) +
          Number.parseInt(earn.amount) / 1e6
        ).toFixed(2);
      }
    });
  }

  return sortObjByKey(total);
};

export const parseAggregatedEarningTotal = (years = []) => {
  let total = {};

  if (years && years.length > 0) {
    years.forEach((year) => {
      const earned = parseAggregatedEarning(year);
      Object.entries(earned).forEach(([denom, amount]) => {
        if (total[denom]) {
          total[denom] += Number.parseFloat(amount);
        }
        total[denom] = Number.parseFloat(amount);
      });
    });
  }
  return total;
};

export const checkIsNodeHealthy = (health) => {
  return health && health.hasOwnProperty("ok") && health["ok"];
};
