import { configureStore } from "@reduxjs/toolkit";
import nodesReducer from "../reducers/nodes.reducer";
import aggregatedStatsReducer from "../reducers/aggregatedStats.reducer";
import historicalReducer from "../reducers/historical.reducer";

export const store = configureStore({
  reducer: {
    nodes: nodesReducer,
    aggregatedStats: aggregatedStatsReducer,
    historical: historicalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 60 },
      serializableCheck: { warnAfter: 60 },
    }),
});
