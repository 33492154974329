import React from "react";
import styles from "../styles/random-node-loader.module.scss";
import ShuffleIcon from "../assets/icons/ShuffleIcon";
import { useDispatch } from "react-redux";
import { fetchRandomNodeData } from "../actions/nodes.actions";

const RandomNodeLoader = () => {
  const dispatch = useDispatch();

  const handleOnLoadRandomNode = (event) => {
    event.preventDefault();
    dispatch(fetchRandomNodeData());
  };

  return (
    <div className={styles.randomNodeLoader}>
      <button onClick={handleOnLoadRandomNode}>
        <span>Random Node</span>
        <ShuffleIcon />
      </button>
    </div>
  );
};

export default React.memo(RandomNodeLoader);
