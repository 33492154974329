import React from "react";

const SentinelLogo = () => {
  return (
    <svg
      width={"120px"}
      height={"48px"}
      viewBox="0 0 201 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_320)">
        <path
          d="M48.9798 2.31547C49.6221 2.31547 50.1412 2.83456 50.1412 3.47689V21.9922V23.1424C50.1562 25.4242 50.1748 28.2736 49.7752 31.2947C49.3122 34.8163 48.3935 37.6806 46.9819 40.052C42.3773 47.7711 34.1055 53.4288 26.3416 54.1831C26.3043 54.1868 26.267 54.1906 26.2296 54.1906C26.1923 54.1906 26.1549 54.1906 26.1176 54.1831C18.3462 53.4213 10.0744 47.7673 5.47733 40.052C4.05824 37.6806 3.14703 34.8126 2.68396 31.2947C2.28811 28.2736 2.30679 25.4279 2.32546 23.1424V21.9922L2.32919 3.47689C2.32919 2.83456 2.84828 2.31547 3.4906 2.31547H48.9798ZM48.9798 0.00012207H3.48687C1.5711 0.00012207 0.0101051 1.56112 0.0101051 3.47689V23.1312C-0.0123015 25.3458 -0.0347082 28.3893 0.383549 31.5935C0.891433 35.4362 1.90347 38.5881 3.48313 41.2358C5.91052 45.3063 9.30139 48.8989 13.2935 51.6325C17.323 54.3885 21.6661 56.0653 25.8599 56.4798C25.9794 56.4947 26.1026 56.5022 26.2259 56.5022C26.3491 56.5022 26.4686 56.4947 26.5919 56.4798C30.7819 56.0653 35.125 54.3922 39.1545 51.6362C43.1466 48.9026 46.5412 45.3063 48.9723 41.2358C50.5558 38.5844 51.5678 35.4325 52.0719 31.5972C52.4902 28.4229 52.4715 25.4951 52.4566 23.1387V21.9848V3.48062C52.4566 1.56112 50.8956 0.00385651 48.9798 0.00385651V0.00012207Z"
          fill="#0156FC"
        />
        <path
          d="M45.8181 25.7578C46.158 26.0154 46.6434 25.7727 46.6434 25.347L46.6322 23.0055V6.31999C46.636 6.03244 46.4044 5.8009 46.1169 5.8009H6.3451C6.05755 5.8009 5.82602 6.03244 5.82602 6.31999V16.5822C5.82602 17.0229 5.92685 17.4561 6.12477 17.8482C6.3227 18.2403 6.60651 18.5839 6.96129 18.8453L36.8779 41.2183C37.1393 41.4125 37.158 41.7897 36.919 42.0138C36.3663 42.5366 35.7837 43.0258 35.1787 43.4889C34.992 43.6308 34.738 43.6308 34.5551 43.4926L6.65133 22.5835C6.31149 22.3295 5.82602 22.5685 5.82602 22.998C5.82602 26.5831 5.50859 33.2714 8.52228 38.3951C11.887 44.12 17.6642 48.6387 23.6094 50.1026C24.4683 50.3117 25.3273 50.4611 26.1862 50.5395C26.2198 50.5432 26.2534 50.5432 26.2833 50.5395C30.1746 50.1885 34.1331 48.4109 37.5314 45.7258C38.3829 45.0499 39.2007 44.3217 39.9775 43.5412C41.5086 41.9988 42.8455 40.2735 43.9472 38.4026C44.9443 36.7109 45.5754 34.8474 45.975 32.9839C46.0646 32.5806 46.1393 32.1735 46.2065 31.7627C46.382 30.6947 45.9488 29.6154 45.0862 28.9694L19.7555 10.0283C19.3596 9.72953 19.565 9.09841 20.0692 9.09841H23.4227C23.5347 9.09841 23.643 9.13576 23.7364 9.20298L45.8219 25.7615L45.8181 25.7578Z"
          fill="#0156FC"
        />
        <path
          d="M66.5979 34.5513C66.5979 37.8114 69.4248 40.6347 74.6381 40.6347C79.8514 40.6347 82.2452 38.2446 82.2452 35.201C82.2452 32.5496 80.5086 30.8542 77.0319 30.2044L71.1651 29.1176C66.2954 28.2064 63.7746 25.6409 63.7746 21.294C63.7746 16.2973 67.7294 12.6002 74.2049 12.6002C81.1584 12.6002 85.5053 16.7305 85.5053 21.9438H82.0286C82.0286 18.6836 79.2016 15.8604 74.2049 15.8604C69.6414 15.8604 67.2514 18.2056 67.2514 21.294C67.2514 23.7289 68.7713 25.4243 72.2481 26.0741L77.0281 26.9442C83.1115 28.0309 85.7219 30.6376 85.7219 35.201C85.7219 40.1977 81.812 43.8948 74.6381 43.8948C67.4642 43.8948 63.1211 39.5479 63.1211 34.5513H66.5979Z"
          fill="white"
        />
        <path
          d="M107.235 37.3785C105.715 41.5498 102.455 43.8988 97.8912 43.8988C92.0244 43.8988 87.4609 39.3353 87.4609 32.5984C87.4609 25.8615 92.0244 21.298 97.8912 21.298C103.758 21.298 107.888 25.6449 107.888 31.7283C107.888 32.5984 107.802 33.2071 107.758 33.5955L107.672 34.1183H90.7211C91.1991 38.2486 94.2427 40.8552 97.8912 40.8552C100.498 40.8552 102.671 39.593 103.758 37.3785H107.235ZM104.628 31.0747C104.412 27.12 101.585 24.3378 97.8912 24.3378C93.9365 24.3378 91.1991 27.12 90.7211 31.0747H104.628Z"
          fill="white"
        />
        <path
          d="M110.492 21.7312H113.315L113.748 24.1212H113.965C114.398 23.5984 114.921 23.1204 115.57 22.732C116.657 22.0374 118.267 21.298 120.481 21.298C125.478 21.298 129.175 24.9913 129.175 30.4249V43.4619H125.915V30.4249C125.915 26.9482 123.308 24.3415 120.048 24.3415C116.571 24.3415 113.744 27.1685 113.744 30.4249V43.4619H110.484V21.7312H110.492Z"
          fill="white"
        />
        <path
          d="M133.742 24.7746H130.266V21.731H133.742V15.6476H137.003V21.731H141.999V24.7746H137.003V39.1185C137.003 39.9887 137.436 40.4219 138.306 40.4219H141.999V43.4654H137.652C135.217 43.4654 133.742 41.9866 133.742 39.7721V24.7783V24.7746Z"
          fill="white"
        />
        <path
          d="M145.039 21.7325H148.299V43.4633H145.039V21.7325Z"
          fill="white"
        />
        <path
          d="M152.429 21.7312H155.253L155.686 24.1212H155.902C156.336 23.5984 156.858 23.1204 157.508 22.732C158.595 22.0374 160.204 21.298 162.419 21.298C167.416 21.298 171.113 24.9913 171.113 30.4249V43.4619H167.853V30.4249C167.853 26.9482 165.246 24.3415 161.986 24.3415C158.509 24.3415 155.682 27.1685 155.682 30.4249V43.4619H152.422V21.7312H152.429Z"
          fill="white"
        />
        <path
          d="M193.497 37.3785C191.977 41.5498 188.716 43.8988 184.153 43.8988C178.286 43.8988 173.723 39.3353 173.723 32.5984C173.723 25.8615 178.286 21.298 184.153 21.298C190.02 21.298 194.15 25.6449 194.15 31.7283C194.15 32.5984 194.064 33.2071 194.019 33.5955L193.933 34.1183H176.983C177.461 38.2486 180.504 40.8552 184.153 40.8552C186.76 40.8552 188.933 39.593 190.02 37.3785H193.497ZM190.89 31.0747C190.673 27.12 187.846 24.3378 184.153 24.3378C180.198 24.3378 177.461 27.12 176.983 31.0747H190.89Z"
          fill="white"
        />
        <path
          d="M196.973 13.0382H200.233V43.459H196.973V13.0382Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_320">
          <rect width="200.872" height="56.4983" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SentinelLogo;
