import React from "react";
import styles from "../styles/current-node-details.module.scss";
import { useSelector } from "react-redux";
import NodeStatsCards from "./NodeStatsCards";
import { NodeDetailsCard } from "../components/Cards";
import Graphs from "./Graphs";

const CurrentNodeDetails = () => {
  const { currentNode, loading } = useSelector((state) => state.nodes);
  return (
    <>
      <div className={styles.currentNodeDetails}>
        <NodeDetailsCard
          loading={loading}
          title={currentNode.moniker}
          totalEarnings={currentNode.totalEarnings}
          location={currentNode.location}
          speed={currentNode.speed}
          connectedUsers={currentNode.peers}
          type={currentNode.type}
          address={currentNode.address}
          prices={currentNode.prices}
          isNodeHealthy={currentNode.isNodeHealthy}
        />
        <NodeStatsCards parentLoading={loading} />
        {/* <SubscriptionPlansCard
          plans={Array(30).fill({
            planName: "sentnode1ylpu9hr4dvmljeq75f0dgnzarjgvmn4lrzatd6",
            prices: { hr: 0, m: 0 },
            currentSubscribedHrs: "10",
          })}
        /> */}
      </div>
      <Graphs parentLoading={loading} />
    </>
  );
};

export default React.memo(CurrentNodeDetails);
