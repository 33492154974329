import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchRandomNodeDataService,
  fetchNodeByAddressService,
  fetchTotalNodesCountService,
  fetchNodeHealthStatusService,
} from "../services/nodes.service";

import { generateRandomNumber } from "../helpers/getRandomNumber";
import { formatNode } from "../helpers/formatNodes";
import { SET_TOTAL_NODES_COUNT } from "../reducers/nodes.reducer";
import { checkIsNodeHealthy } from "../helpers/parseValues";

export const fetchTotalNodesCount = async () => {
  try {
    let totalNodes = await fetchTotalNodesCountService();
    return totalNodes;
  } catch (e) {
    return 0;
  }
};

export const fetchRandomNodeData = createAsyncThunk(
  "FETCH_RANDOM_NODE_DATA",
  async (_, { dispatch, fulfillWithValue, rejectWithValue, getState }) => {
    try {
      let totalNodes = getState().nodes.totalNodes;

      if (totalNodes === 0) {
        totalNodes = await fetchTotalNodesCount();
        dispatch(SET_TOTAL_NODES_COUNT(totalNodes));
      }

      const randomNumber = generateRandomNumber(totalNodes);
      const response = await fetchRandomNodeDataService(randomNumber);
      const health = await fetchNodeHealthStatusService(response.addr);
      const isNodeHealthy = checkIsNodeHealthy(health);
      const node = formatNode(response, null, isNodeHealthy);

      return fulfillWithValue(node);
    } catch (e) {
      return rejectWithValue();
    }
  }
);

export const fetchNodeByAddress = createAsyncThunk(
  "FETCH_NODE_BY_ADDRESS",
  async (addr, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await fetchNodeByAddressService(addr);
      const health = await fetchNodeHealthStatusService(addr);
      const isNodeHealthy = checkIsNodeHealthy(health);
      const node = formatNode(response, addr, isNodeHealthy);
      return fulfillWithValue(node);
    } catch (e) {
      return rejectWithValue({ addr });
    }
  }
);

export const fetchNodeHealthStatus = createAsyncThunk(
  "FETCH_NODE_HEALTH_STATUS",
  async (addr, { fulfillWithValue, rejectWithValue }) => {
    try {
      const resp = await fetchNodeHealthStatusService(addr);
      const isNodeHealthy = checkIsNodeHealthy(resp);
      return fulfillWithValue({ isNodeHealthy });
    } catch (e) {
      return rejectWithValue({ addr });
    }
  }
);
