export const graphModel = {
  duration: "day",
  loading: true,
  day: {
    labels: [],
    values: {},
  },
  week: {
    labels: [],
    values: {},
  },
  month: {
    labels: [],
    values: {},
  },
  year: {
    labels: [],
    values: {},
  },
};
