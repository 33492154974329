import React from "react";
import styles from "../styles/graphs.module.scss";
import { GraphCard } from "../components/Cards";
import BGLeftIris from "../assets/images/bg-left-irislight.svg";
import BGRightIris from "../assets/images/bg-right-irislight.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchActiveSessions,
  dispatchActiveSubscriptions,
  dispatchBandwidthConsumtion,
  dispatchDVPNEarning,
} from "../actions/historical.actions";

const GraphsBG = () => {
  return (
    <section className={styles.graphsBG}>
      <img className={styles.graphsBGLeft} src={BGLeftIris} alt="" />
      <img className={styles.graphsBGRight} src={BGRightIris} alt="" />
    </section>
  );
};

const dispatchers = {
  dispatchActiveSessions,
  dispatchActiveSubscriptions,
  dispatchBandwidthConsumtion,
  dispatchDVPNEarning,
};

const Graphs = ({ parentLoading }) => {
  const historicals = useSelector((state) => state.historical);

  const { address } = useSelector((state) => state.nodes.currentNode);
  const dispatch = useDispatch();

  const graphs = React.useMemo(
    () => (
      <div className={styles.graphsCards}>
        {Object.values(historicals).map((graph) => (
          <GraphCard
            key={graph.title}
            title={graph.title}
            parentLoading={parentLoading}
            isLoading={graph.loading}
            duration={graph.duration}
            values={graph[graph.duration].values}
            labels={graph[graph.duration].labels}
            isBandwidth={graph.isBandwidth}
            onDurationChange={(duration) =>
              dispatch(
                dispatchers[graph.dispatcher]({
                  address,
                  duration,
                })
              )
            }
          />
        ))}
      </div>
    ),
    [address, dispatch, historicals, parentLoading]
  );

  return (
    <div className={styles.graphs}>
      <GraphsBG />
      {graphs}
    </div>
  );
};

export default React.memo(Graphs);
