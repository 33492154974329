import {
  parseAggregatedEarning,
  parseAggregatedEarningTotal,
  parseBandwidthValue,
  parseNodeLocation,
  parseNodePrices,
  parseTimeStamp,
  parseTotalDataProvided,
  parseValueNotAvailable,
  sortObjByKey,
} from "./parseValues";

const types = {
  1: "WireGuard",
  2: "V2Ray",
};

export const formatNode = (node, addr, isNodeHealthy) => {
  return {
    address: addr ? addr : node.addr,
    moniker: parseValueNotAvailable(node.moniker),
    speed: parseValueNotAvailable(node.internet_speed),
    location: parseNodeLocation(node.location),
    peers: node.peers || 0,
    type: types[node.type],
    version: parseValueNotAvailable(node.version),
    remote_url: parseValueNotAvailable(node.remote_url),
    reach_status: parseValueNotAvailable(node.reach_status),
    prices: {
      hr: sortObjByKey(parseNodePrices(node.hourly_prices)),
      gb: sortObjByKey(parseNodePrices(node.gigabyte_prices)),
    },
    totalEarnings: "",
    isNodeHealthy,
  };
};

export const formatAggregatedStats = (stats = []) => {
  const { day, week, month, year } = stats;

  const networkRevenue = {
    day: day && day.length > 0 ? parseAggregatedEarning(day[0]) : {},
    week: week && week.length > 0 ? parseAggregatedEarning(week[0]) : {},
    month: month && month.length > 0 ? parseAggregatedEarning(month[0]) : {},
    total: year && year.length > 0 ? parseAggregatedEarningTotal(year) : {},
  };

  const dataProvided = {
    day:
      day && day.length > 0
        ? parseTotalDataProvided(day[0].session_bandwidth)
        : null,
    month:
      month && month.length > 0
        ? parseTotalDataProvided(month[0].session_bandwidth)
        : null,
    week:
      week && week.length > 0
        ? parseTotalDataProvided(week[0].session_bandwidth)
        : null,
  };

  const uniqueUsers = {
    day: day && day.length > 0 ? day[0].session_address : null,
    month: month && month.length > 0 ? month[0].session_address : null,
    week: week && week.length > 0 ? week[0].session_address : null,
  };

  return {
    dataProvided,
    uniqueUsers,
    networkRevenue,
  };
};

export const formatBandwidthConsumption = (stats, duration) => {
  const { labels, Upload, Download } = parseBandwidthValue(stats, duration);
  return {
    labels,
    values: { Upload, Download },
  };
};

export const formatActiveSubscriptions = (stats = [], duration) => {
  const labels = [];
  const subscriptions = [];

  stats.forEach((stat) => {
    if (stat.active_subscription) {
      labels.push(parseTimeStamp(stat.timestamp, duration));
      subscriptions.push(stat.active_subscription);
    }
  });
  return {
    labels: labels,
    values: { "Active Subscriptions": subscriptions },
  };
};

export const formatActiveSessions = (stats, duration) => {
  const labels = [];
  const sessions = [];

  stats.forEach((stat) => {
    if (stat.active_session) {
      labels.push(parseTimeStamp(stat.timestamp, duration));
      sessions.push(stat.active_session);
    }
  });
  return {
    labels: labels,
    values: { "Active Sessions": sessions },
  };
};

export const formatDVPNEarnings = (stats, duration) => {
  const labels = [];
  const dvpn = [];

  stats.forEach((stat) => {
    if (stat.bytes_earning || stat.hours_earning) {
      let value = 0;
      if (stat.bytes_earning) {
        stat.bytes_earning.forEach((v) => {
          if (v.denom === "udvpn") value += Number.parseInt(v.amount);
        });
      }
      if (stat.hours_earning) {
        stat.hours_earning.forEach((v) => {
          if (v.denom === "udvpn") value += Number.parseInt(v.amount);
        });
      }

      value = (value / 1e6).toFixed(2);
      if (value > 0) {
        labels.push(parseTimeStamp(stat.timestamp, duration));
        dvpn.push(value);
      }
    }
  });

  return {
    labels,
    values: { "DVPN Earned": dvpn },
  };
};
